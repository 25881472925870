import axios from 'axios';

// Crea una instancia de Axios con la URL base de la API
const api = axios.create({
  baseURL: 'http://localhost:8080/api/v1',
});

// Clave API
const apiKey = 'hkj4KJ8hsdf9jskj3KJ8sd9Ks2JkBsz3kKsdjhs9J';

// Sitekey para ReCAPTCHA
const reCAPTCHAKey = '6LdbykkqAAAAAD93-stRZX7uyc4-oaAX6U7oXjSI'; // Cambia por tu clave de sitio

// Agrega un interceptor de solicitud para incluir la API Key o el token JWT según el endpoint
api.interceptors.request.use(
  (config) => {
    if (apiKey) {
      config.headers['API-KEY'] = apiKey;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { api, reCAPTCHAKey };
