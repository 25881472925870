import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';
import Marquee from './components/Marquee';


import BuscadorMunicipios from './components/BuscadorMunicipios';
import Inicio from './pages/Inicio';
import Calibrar from './pages/Calibrar';
import Mantenimiento from './pages/Mantenimiento';
import Supervision from './pages/Supervision';
import RegistroInicial from './pages/RegistroInicial';
//import RegistroInicial from './RegistroInicial';
//import ConfirmacionRegistro from './ConfirmacionRegistro';
import './styles/App.css';

const App = () => {
  const location = useLocation();
  
  const shouldShowMarquee = () => {
    const path = location.pathname;
    return ['/calibrar', '/mantenimiento', '/supervision'].includes(path);
  };

  return (
    <>
      <Header />
      <Menu />
      {shouldShowMarquee() && <Marquee />}
      <main className="container mt-4" style={{ paddingTop: '150px', paddingBottom: '150px' }}>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/RegistroInicial" element={<RegistroInicial />} />
          <Route path="/calibrar" element={<Calibrar />} />
          <Route path="/mantenimiento" element={<Mantenimiento />} />
          <Route path="/supervision" element={<Supervision />} />
          <Route path="/buscar" element={<BuscadorMunicipios />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
