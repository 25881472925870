import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Menu.css'; // Asegúrate de que este archivo CSS esté configurado correctamente

const Menu = () => {
  return (
    <nav className="menu">
      <Nav className="justify-content-center bg-dark py-2">
        <Nav.Item>
          <Nav.Link as={Link} to="/" className="text-light">Inicio</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/calibrar" className="text-light">Calibrar</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/mantenimiento" className="text-light">Mantenimiento</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/supervision" className="text-light">Supervisión</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/contacto" className="text-light">Contacto</Nav.Link>
        </Nav.Item>
      </Nav>
    </nav>
  );
};

export default Menu;



