// Marquee.js
import React from 'react';
import './Marquee.css'; // Asegúrate de que la ruta sea correcta

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee">
        Regístrate para tener más información es GRATIS!!!!!
      </div>
    </div>
  );
};

export default Marquee;
