import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/RegistroInicial.css';
import { api, reCAPTCHAKey } from '../auth.js'; // Importa reCAPTCHAKey
import ReCAPTCHA from 'react-google-recaptcha';

const RegistroInicial = () => {
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState('');
  const [selectedCiudad, setSelectedCiudad] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    correo: '',
    nombre: '',
    apellidos: '',
    telefono: '',
    departamento: '',
    ciudad_usuario: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const navigate = useNavigate();

  const fetchDepartamentos = async () => {
    try {
      const response = await api.get('/departamentos');
      setDepartamentos(response.data);
    } catch (error) {
      console.error('Error al obtener departamentos:', error.response?.data || error.message);
    }
  };

  const fetchCiudades = async () => {
    if (selectedDepartamento) {
      try {
        const response = await api.get(`/municipios/departamento/${selectedDepartamento}`);
        setCiudades(response.data);
      } catch (error) {
        console.error('Error al obtener ciudades:', error.response?.data || error.message);
      }
    } else {
      setCiudades([]);
    }
  };

  useEffect(() => {
    fetchDepartamentos();
  }, []);

  useEffect(() => {
    fetchCiudades();
  }, [selectedDepartamento]);

  const handleDepartamentoChange = (e) => {
    setSelectedDepartamento(e.target.value);
    setSelectedCiudad('');
    setFormData({ ...formData, departamento: e.target.value });
  };

  const handleCiudadChange = (e) => {
    setSelectedCiudad(e.target.value);
    setFormData({ ...formData, ciudad_usuario: e.target.value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!emailPattern.test(formData.correo)) {
      errors.correo = 'El correo electrónico no es válido.';
    }
    if (formData.telefono && !phonePattern.test(formData.telefono)) {
      errors.telefono = 'El número de teléfono debe tener 10 dígitos.';
    }
    if (!selectedDepartamento) {
      errors.departamento = 'Debes seleccionar un departamento.';
    }
    if (!selectedCiudad) {
      errors.ciudad_usuario = 'Debes seleccionar una ciudad.';
    }
    if (!captchaValue) {
      errors.captcha = 'Debes verificar que no eres un robot.';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await api.post('/usuarios/registro', {
          ...formData,
          departamento: selectedDepartamento,
          ciudad_usuario: selectedCiudad,
        });
        alert(response.data);
        navigate('/');
      } catch (error) {
        console.error('Error al registrar el usuario:', error.response?.data || error.message);
        alert('Error al registrar el usuario: ' + (error.response?.data || error.message));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="registro-inicial">
      <form className="formulario" onSubmit={handleSubmit}>
        <h1>Registro Inicial</h1>
        <div className="form-group">
          <label htmlFor="correo">Correo</label>
          <input
            type="email"
            id="correo"
            name="correo"
            value={formData.correo}
            onChange={handleInputChange}
            required
          />
          {errors.correo && <div className="error">{errors.correo}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="nombre">Nombre</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="apellidos">Apellidos</label>
          <input
            type="text"
            id="apellidos"
            name="apellidos"
            value={formData.apellidos}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefono">Teléfono</label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            value={formData.telefono}
            onChange={handleInputChange}
          />
          {errors.telefono && <div className="error">{errors.telefono}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="departamento">Departamento</label>
          <select
            id="departamento"
            value={selectedDepartamento}
            onChange={handleDepartamentoChange}
            required
          >
            <option value="">Selecciona un departamento</option>
            {departamentos.map(dpto => (
              <option key={dpto.codigo} value={dpto.codigo}>{dpto.nombre}</option>
            ))}
          </select>
          {errors.departamento && <div className="error">{errors.departamento}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="ciudad_usuario">Ciudad</label>
          <select
            id="ciudad_usuario"
            value={selectedCiudad}
            onChange={handleCiudadChange}
            required
          >
            <option value="">Selecciona una ciudad</option>
            {ciudades.map(ciudad => (
              <option key={ciudad.codigo} value={ciudad.codigo}>{ciudad.nombre}</option>
            ))}
          </select>
          {errors.ciudad_usuario && <div className="error">{errors.ciudad_usuario}</div>}
        </div>

        {/* Checkbox para aceptar términos y condiciones */}
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              required
            />
            Acepto el uso de mis datos personales.{" "}
            <a href="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">Leer más</a>
          </label>
        </div>

        {/* ReCAPTCHA */}
        <div className="form-group">
          <ReCAPTCHA
            sitekey={reCAPTCHAKey} // Usa la sitekey desde auth.js
            onChange={setCaptchaValue}
          />
          {errors.captcha && <div className="error">{errors.captcha}</div>}
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Registrando...' : 'Registrarse'}
        </button>
        <button type="button" onClick={() => navigate('/')}>Regresar al inicio</button>
      </form>

      {loading && (
        <div className="loader">
          <div className="spinner"></div>
          Cargando...
        </div>
      )}
    </div>
  );
};

export default RegistroInicial;
