import React from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logometro from '../assets/images/METROCDA1.png';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <Navbar className="bg-gradient w-100">
        <Container className="d-flex justify-content-between align-items-center">
          <Navbar.Brand href="#home" className="d-flex align-items-center">
            <img
              src={logometro}
              width="350"
              height="auto"
              className="d-inline-block align-top"
              alt="logometro"
            />
            <span>Asegurando las mediciones de los CDA's</span>
          </Navbar.Brand>
          <Nav>
            <Button variant="secondary" className="me-2">Iniciar Sesión</Button>
            <Link to="/RegistroInicial">
              <Button variant="secondary">Registrarte</Button>
            </Link>
          </Nav>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
